.collection {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 20px;
  position: relative;
  span {
    position: relative;
    width: 100% !important;
  }
  .collection_slide_main_container {
    margin-top: 40px;
  }
  .collectionheader {
    border-top: 1px solid #ddd;
    padding-top: 30px;
    padding-bottom: 20px;
    h2 {
      color: #000;
      font-weight: 600;
      font-size: 25px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      font-family: "Lato", sans-serif;
    }
    p {
      color: #727272;
      text-align: center;
      font-size: 18px;
      font-family: "Lato", sans-serif;
      margin-bottom: 0px;
    }
  }
  .slide_img {
    width: 100%;
    position: relative;
  }
  .collection_slide_item {
    position: relative;
    width: 100%;
    cursor: pointer;
    .slide_img {
      position: relative;
      width: 95%;
      height: auto !important;
      img {
        width: 100% !important;
        object-fit: cover;
      }
    }
    .port_text {
      position: absolute;
      top: 0px;
      left: 0px;
      padding: 15px 20px;
      width: 95%;
      clear: both;
      display: flow-root;
      background: -moz-linear-gradient(
        to bottom,
        rgba(19, 19, 19, 0.9) 0%,
        rgba(241, 241, 241, 0) 100%
      );
      background: -webkit-gradient(
        to bottom,
        rgba(19, 19, 19, 0.9) 0%,
        rgba(241, 241, 241, 0) 100%
      );
      background: -webkit-linear-gradient(
        to bottom,
        rgba(19, 19, 19, 0.9) 0%,
        rgba(241, 241, 241, 0) 100%
      );
      background: -o-linear-gradient(
        to bottom,
        rgba(19, 19, 19, 0.9) 0%,
        rgba(241, 241, 241, 0) 100%
      );
      background: -ms-linear-gradient(
        to bottom,
        rgba(19, 19, 19, 0.9) 0%,
        rgba(241, 241, 241, 0) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(19, 19, 19, 0.9) 0%,
        rgba(241, 241, 241, 0) 100%
      );
      .h3_sub {
        font-size: 30px;
        color: #fff;
        font-family: "newyork";
        margin-bottom: 0px;
      }
      p {
        margin-bottom: 0px;
        text-align: left;
        color: #fff;
      }
    }
    .port_btn {
      position: absolute;
      bottom: 6px;
      left: 0px;
      padding: 15px 20px;
      color: #fff;
      width: 95%;
      background: -moz-linear-gradient(
        to bottom,
        rgba(241, 241, 241, 0) 0%,
        rgba(19, 19, 19, 0.76) 100%
      );
      background: -webkit-gradient(
        to bottom,
        rgba(241, 241, 241, 0) 0%,
        rgba(19, 19, 19, 0.76) 100%
      );
      background: -webkit-linear-gradient(
        to bottom,
        rgba(241, 241, 241, 0) 0%,
        rgba(19, 19, 19, 0.76) 100%
      );
      background: -o-linear-gradient(
        to bottom,
        rgba(241, 241, 241, 0) 0%,
        rgba(19, 19, 19, 0.76) 100%
      );
      background: -ms-linear-gradient(
        to bottom,
        rgba(241, 241, 241, 0) 0%,
        rgba(19, 19, 19, 0.76) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(241, 241, 241, 0) 0%,
        rgba(19, 19, 19, 0.76) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 );
      a {
        font-size: 16px;
        font-weight: 600;
        &:hover {
          color: #fff;
        }
      }
    }
  }
  .collection_image {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .slick-prev {
    background-color: #000;
  }
}
